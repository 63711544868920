import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import BottleCanIcon from "../../images/icn-botcan.svg";
import NewIcon from "../../images/icn-new.svg";
import GlutenIcon from "../../images/icn-glutenfree.svg";
import CanIcon from "../../images/can-icon.svg";
import reduxStore from '../../redux/reduxStore';
import { setSliceOverlay } from '../../redux/actions/actions';
import { useSelector } from 'react-redux';
import * as styles from "../../styles/List.module.scss";

const List = ({ slice }) => {
  let listItems;
  const [activeListItemIndex, setActiveListItemIndex] = useState(null);
  const sliceOverlayActive = useSelector(state => state.sliceOverlayActive);

  const listItemClickHandler = (index) => {
    if (activeListItemIndex !== index) {
      setActiveListItemIndex(index);
      reduxStore.dispatch(setSliceOverlay(true));
    } else {
      setActiveListItemIndex(null);
      reduxStore.dispatch(setSliceOverlay(false));
    }
  }

  useEffect(() => {
    if(!sliceOverlayActive) {
      setActiveListItemIndex(null);
    }
  }, [sliceOverlayActive, setActiveListItemIndex])

  if (slice.items) {
    listItems = slice.items.map((listItem, index) => {
      let item;
      if (listItem.list_item_link.url) {
        item = (
          <li className={styles.ListItem}>
            <div className={styles.ListItemWrap}>
              <a className={styles.ListItemLink} href={listItem.list_item_link.url} target="_blank" rel='noreferrer'><div className={styles.ListItemHeading}><PrismicRichText field={listItem.list_item_title.richText} /></div>
                {listItem.new_tap ? <img src={NewIcon} alt="New Tap" className={[styles.CanIcon, styles.NewIcon].join(' ')} /> : ''}
                {listItem.available_in_cans && slice.primary.list_type === 'Placard' ? <img src={BottleCanIcon} alt="Available in cans" className={styles.CanIcon} /> : ''}
                {listItem.available_in_cans && slice.primary.list_type === 'Standard' ? <img src={CanIcon} alt="Available in cans" className={styles.CanIconStandard} /> : ''}
                {listItem.gluten_free ? <img src={GlutenIcon} alt="Gluten Free" className={styles.CanIcon} /> : ''}</a>
            </div>
          </li>
        );
      } else {
        item = (
          <li className={[styles.ListItem, activeListItemIndex === index && sliceOverlayActive ? styles.ListItemActive : ''].join(' ')} onClick={() => listItemClickHandler(index)} onKeyDown={() => listItemClickHandler(index)}>
            <div className={styles.ListItemWrap}>
              <div className={styles.ListItemHeading}><PrismicRichText field={listItem.list_item_title.richText} /></div>
              {listItem.new_tap ? <img src={NewIcon} alt="New Tap" className={[styles.CanIcon, styles.NewIcon].join(' ')} /> : ''}
              {listItem.available_in_cans && slice.primary.list_type === 'Placard' ? <img src={BottleCanIcon} alt="Available in cans" className={styles.CanIcon} /> : ''}
              {listItem.available_in_cans && slice.primary.list_type === 'Standard' ? <img src={CanIcon} alt="Available in cans" className={styles.CanIconStandard} /> : ''}
              {listItem.gluten_free ? <img src={GlutenIcon} alt="Gluten Free" className={styles.CanIcon} /> : ''}
            </div>
            <div className={[styles.ListItemCopy, activeListItemIndex === index && sliceOverlayActive ? styles.ListItemCopyShow : ''].join(' ')} id={'list-item-copy-' + index}>
              <PrismicRichText field={listItem.list_item_copy.richText} />
            </div>
          </li>
        );
      }
      if(listItem.display){
        return (
          <div key={index} className={styles.ListItemMainWrap}>{item}</div>
        );
      } else {
        return null;
      }
      
    });
  }
  return (
    <div className={[styles.ListWrap, slice.primary.list_type === 'Standard' ? styles.ListTypeStandard : styles.ListTypePlacard].join(' ')}>
      {slice.primary.list_title ? <div className={styles.ListTitle}><PrismicRichText field={slice.primary.list_title.richText} /> </div> : ''}
      {slice.primary.list_disclaimer ? <PrismicRichText field={slice.primary.list_disclaimer.richText} /> : ''}
      <ul className={[styles.ListItemsWrap, slice.primary.list_type === 'Placard' ? styles.ListPlacard : styles.ListStandard].join(' ')}>{listItems}</ul>
    </div>
  );
}

export const query = graphql`
    fragment SectionDataBodyList on PrismicSectionDataBodyList {
        primary {
            list_title {
              richText
            }
            list_disclaimer {
              richText
            }
            list_type
        }
        items {
            available_in_cans
            new_tap
            gluten_free
            display
            list_item_title {
              richText
            }
            list_item_copy {
              richText
            }
            list_item_link {
              url
            }
          }
    }
`;
export default List;
