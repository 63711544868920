import React, { useRef, useEffect } from 'react';
import { SliceZone, PrismicRichText } from '@prismicio/react';
import { components } from './slices'
import * as styles from '../styles/Section.module.scss';
import { useSelector } from 'react-redux';
import reduxStore from '../redux/reduxStore';
import { setSliceOverlay } from '../redux/actions/actions';
import { StaticImage } from 'gatsby-plugin-image';

const Section = ({ data, observeElement }) => {
    let uid = data.homepage_section.document.uid;
    let sectionData = data.homepage_section.document.data;
    const sliceOverlayActive = useSelector(state => state.sliceOverlayActive);
    const ref = useRef();
    useEffect(() => {
        if (ref.current) observeElement(ref.current)
    }, [observeElement]);

    return (
        <section ref={ref} title={sectionData.section_title ? sectionData.section_title.text : ''} className={[styles.SectionWrap, sectionData.full_width ? styles.FullWidthSection : '', uid === "events" ? styles.EventsSection : '', uid === "food" ? styles.FoodSection : '', uid === "our-story" ? styles.OurStory : '',  uid === "cellar" ? styles.Cellar : ''].join(' ')} style={{ background: "url(" + sectionData.section_background_image.url + ")", backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
            {sectionData.section_title && sectionData.show_section_title ? <div className={[styles.SectionTitle, sectionData.full_width ? styles.SectionTitleFullWidth : '', sectionData.section_title.richText[0].label ? sectionData.section_title.richText[0].label : ''].join(' ')}><PrismicRichText field={sectionData.section_title.richText} /></div> : ''}
            <div id={uid} className={styles.SectionAnchor}></div>
            {sectionData.section_disclaimer ? <div className={[styles.SectionDisclaimer, uid === "find-it-in-the-wild" ? styles.SectionDisclaimerLeft : '', sectionData.form_section ? styles.SectionFormDisclaimer : ''].join(' ')}><PrismicRichText field={sectionData.section_disclaimer.richText} /></div> : ''}
            <div className={styles.SectionSliceWrap}>
                <div className={[styles.SectionSliceOverlay, sliceOverlayActive && uid === "tap-list" ? styles.SectionSliceOverlayActive : '', sectionData.section_overlay ? styles.SectionOverlay : ''].join(' ')} onClick={() => reduxStore.dispatch(setSliceOverlay(false))} onKeyDown={() => reduxStore.dispatch(setSliceOverlay(false))}></div>
                {uid === 'our-story' ? <div className={styles.OurStoryStamp}><StaticImage src="../images/stamp.png" alt="Homeplace Stamp" width={240} formats={["AUTO", "WEBP", "AVIF"]} placeholder="blur"/></div> : ''}
                <SliceZone slices={data.homepage_section.document.data.body} components={components} />
            </div>
        </section>
    );
};

export default Section;