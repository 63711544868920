import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import * as styles from '../../styles/TwoThirdsBackground.module.scss';
import { useSelector } from 'react-redux';
import { PrismicRichText } from '@prismicio/react';

const TwoThirdsBackground = ({ slice }) => {
    const [openCopy, setOpenCopy] = useState(null);
    const isMobile = useSelector(state => state.isMobile);
    const isTablet = useSelector(state => state.isTablet);

    useEffect(() => {
        const openTimes = [
            {
                day: 1, // Monday
                openTimeCopy: '4:00PM - 8:00PM',
                open: {
                    hours: 16,
                    minutes: 0
                },
                close: {
                    hours: 20,
                    minutes: 0
                }
            },
            {
                day: 2, // Tuesday
                openTimeCopy: '11:30AM - 09:00PM',
                open: {
                    hours: 11,
                    minutes: 30
                },
                close: {
                    hours: 21,
                    minutes: 0
                }
            },
            {
                day: 3, // Wednesday
                openTimeCopy: '11:30AM - 09:00PM',
                open: {
                    hours: 11,
                    minutes: 30
                },
                close: {
                    hours: 21,
                    minutes: 0
                }
            },
            {
                day: 4, // Thurdsay
                openTimeCopy: '11:30AM - 09:00PM',
                open: {
                    hours: 11,
                    minutes: 30
                },
                close: {
                    hours: 21,
                    minutes: 0
                }
            },
            {
                day: 5, // Friday
                openTimeCopy: '11:30AM - 11:00PM',
                open: {
                    hours: 11,
                    minutes: 30
                },
                close: {
                    hours: 23,
                    minutes: 0
                }
            },
            {
                day: 5, // Friday
                openTimeCopy: '11:30AM - 11:00PM',
                open: {
                    hours: 11,
                    minutes: 30
                },
                close: {
                    hours: 23,
                    minutes: 0
                }
            },
            {
                day: 6, // Saturday
                openTimeCopy: '11:30AM - 11:00PM',
                open: {
                    hours: 11,
                    minutes: 30
                },
                close: {
                    hours: 23,
                    minutes: 0
                }
            },
            {
                day: 0, // Sunday
                openTimeCopy: '2:00PM - 8:00PM',
                open: {
                    hours: 14,
                    minutes: 0
                },
                close: {
                    hours: 20,
                    minutes: 0
                }
            }
        ];
        let current = new Date();
        let dayOfWeek = current.getDay();

        for (let i = 0; i < openTimes.length; i++) {
            if (openTimes[i].day === dayOfWeek) {
                setOpenCopy(openTimes[i].openTimeCopy);
            }
        }
    }, [setOpenCopy]);

    let backgroundStyles;
    if(isMobile || isTablet) {
        backgroundStyles = {
            background: "url(" + slice.primary.background_image.url + ") no-repeat center / cover",
        };
    } else {
        backgroundStyles = {
            background: "url(" + slice.primary.background_image_desktop.url + ") no-repeat center / cover",
        };
    }

    return (
        <div className={styles.TwoThirdsBackgroundWrap} style={backgroundStyles}>
            <div className={styles.TwoThirdsBackgroundTitle}>
                <div className={styles.TwoThirdsBackgroundTitleText}>{openCopy ? "Open Today" : "We are currently closed"}<br />{openCopy ? openCopy : ''}</div>
            </div>
            <div className={styles.CopyBlock}>
                <PrismicRichText field={slice.primary.copy.richText} />
            </div>
        </div>
    );
}

export const query = graphql`
    fragment SectionDataBodyTwoThirdsBackground on PrismicSectionDataBodyTwothirdsBackgroundImage {
        primary {
            background_image {
                url
              }
              background_image_desktop {
                url
              }
              copy {
                richText
              }
          }
    }
`;

export default TwoThirdsBackground;