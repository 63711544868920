import React from 'react';
import { graphql } from 'gatsby';
import * as styles from '../../styles/CenteredLogo.module.scss';

const CenteredLogo = ({ slice }) => {
    return (
        slice.primary.logo_link ? <a href={slice.primary.logo_link.url} className={styles.CenteredLogoLink} target="_blank" rel='noreferrer'><img src={slice.primary.logo_image.url} alt={slice.primary.logo_image.alt} /></a> : <img className={styles.CenteredLogoImg} src={slice.primary.logo_image.url} alt={slice.primary.logo_image.alt} />
    );
}

export const query = graphql`
    fragment SectionDataBodyCenteredLogo on PrismicSectionDataBodyCenteredLogo {
        primary {
            logo_image {
              url
            }
            logo_link {
                url
              }
        }
    }
`;
export default CenteredLogo;
