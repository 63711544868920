// extracted by mini-css-extract-plugin
export var FloorPlanImage = "Venue-module--FloorPlanImage--YN2XY";
export var VenueBackgroundImage = "Venue-module--VenueBackgroundImage--U2HMg";
export var VenueBodyWrap = "Venue-module--VenueBodyWrap--gjq4G";
export var VenueBookButtonWrap = "Venue-module--VenueBookButtonWrap--wvv0C";
export var VenueButton = "Venue-module--VenueButton--q4iI7";
export var VenueButtonsWrap = "Venue-module--VenueButtonsWrap--3Y53P";
export var VenueDescDesktop = "Venue-module--VenueDescDesktop--YIdvI";
export var VenueFloorPlanIcon = "Venue-module--VenueFloorPlanIcon--a8Ocb";
export var VenueFloorPlanModalWrap = "Venue-module--VenueFloorPlanModalWrap--XfQub";
export var VenueVRModalWrap = "Venue-module--VenueVRModalWrap--xJo1T";
export var VenueVideoWrap = "Venue-module--VenueVideoWrap--hfG4r";
export var VenueVrTour = "Venue-module--VenueVrTour--tKpcX";
export var VenueWrap = "Venue-module--VenueWrap--AFO6A";
export var jobs = "Venue-module--jobs--nH+ib";