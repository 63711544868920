// extracted by mini-css-extract-plugin
export var ColorBlack = "Section-module--ColorBlack--SHcTv";
export var EventsSection = "Section-module--EventsSection--bcsiL";
export var FoodSection = "Section-module--FoodSection--XJSHe";
export var FullWidthSection = "Section-module--FullWidthSection--t+JHp";
export var OurStory = "Section-module--OurStory--sGHBQ";
export var OurStoryStamp = "Section-module--OurStoryStamp--BYtc0";
export var SectionAnchor = "Section-module--SectionAnchor--+DYWq";
export var SectionDisclaimer = "Section-module--SectionDisclaimer--bBDNJ";
export var SectionDisclaimerLeft = "Section-module--SectionDisclaimerLeft--0BBLb";
export var SectionFormDisclaimer = "Section-module--SectionFormDisclaimer--u+gFj";
export var SectionOverlay = "Section-module--SectionOverlay--D1LSU";
export var SectionSliceOverlay = "Section-module--SectionSliceOverlay--ZjM+G";
export var SectionSliceOverlayActive = "Section-module--SectionSliceOverlayActive--nyJX5";
export var SectionSliceWrap = "Section-module--SectionSliceWrap--n4TIy";
export var SectionTitle = "Section-module--SectionTitle--x8Drj";
export var SectionTitleFullWidth = "Section-module--SectionTitleFullWidth--fDYA7";
export var SectionWrap = "Section-module--SectionWrap--2Erbk";
export var jobs = "Section-module--jobs--XMavE";