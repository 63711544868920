// extracted by mini-css-extract-plugin
export var CanIcon = "List-module--CanIcon--vqIz0";
export var CanIconStandard = "List-module--CanIconStandard--w5Q7b";
export var ListItem = "List-module--ListItem--GPtgS";
export var ListItemActive = "List-module--ListItemActive--nAUB2";
export var ListItemCopy = "List-module--ListItemCopy--C02m2";
export var ListItemCopyShow = "List-module--ListItemCopyShow--R1O4A";
export var ListItemHeading = "List-module--ListItemHeading--8CRhI";
export var ListItemLink = "List-module--ListItemLink--vRWwJ";
export var ListItemMainWrap = "List-module--ListItemMainWrap--X6wNy";
export var ListItemWrap = "List-module--ListItemWrap--dqOZb";
export var ListItemsWrap = "List-module--ListItemsWrap--eGE3v";
export var ListPlacard = "List-module--ListPlacard--0HB7a";
export var ListStandard = "List-module--ListStandard--VFO5J";
export var ListTitle = "List-module--ListTitle--fYJ6N";
export var ListTypePlacard = "List-module--ListTypePlacard--WlVrI";
export var ListTypeStandard = "List-module--ListTypeStandard--YxMzA";
export var ListWrap = "List-module--ListWrap--Qw1PV";
export var NewIcon = "List-module--NewIcon--YOD5V";
export var jobs = "List-module--jobs--Ihyxr";