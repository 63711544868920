import React from 'react'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'
import Homepage from '../components/Homepage';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

const HomeTemplate = ({ data }) => {
  if (!data) return null;
  const heroDoc = data.prismicHomepage.data.hero.document.data;
  const homepageSections = data.prismicHomepage.data.homepage_sections;
  const interstitialDoc = data.prismicInterstitial.data;

  return <Layout isHomepage>
    <Seo title="Home" />
    <Helmet>
      <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/22452043.js"></script>
    </Helmet>
    <Homepage heroData={heroDoc} homepageSections={homepageSections} interstitialData={interstitialDoc} />
  </Layout>
}

export const query = graphql`
query Home {
  prismicInterstitial {
    data {
      interstitial_copy {
        richText
      }
    }
  }
  prismicHomepage {
    data {
      hero {
        document {
          ... on PrismicHero {
            id
            data {
              hero_background_image {
                url
              }
              hero_background_embed {
                text
              }
              hero_background_embed_tablet {
                text
              }
              hero_background_embed_desktop {
                text
              }
              hero_background_mobile {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              hero_background_tablet {
                url
              }
              hero_background_desktop {
                url
              }
            }
          }
        }
      }
      homepage_sections {
        homepage_section {
          document {
            ... on PrismicSection {
              id
              uid
              data {
                section_title {
                  text,
                  richText
                }
                show_section_title
                section_header_link {
                  text
                }
                section_background_image{
                  url
                }
                section_disclaimer {
                  richText
                }
                full_width
                section_overlay
                form_section
                body{
                  ... on PrismicSliceType {
                    slice_type
                  }
                  ...SectionDataBodyCarousel
                  ...SectionDataBodyCenteredLogo
                  ...SectionDataBodyContentBlock
                  ...SectionDataBodyFullBackgroundWCta
                  ...SectionDataBodyList
                  ...SectionDataBodyEvents
                  ...SectionDataBodyTwoThirdsBackground
                  ...SectionDataBodyVenue
                  ...SectionDataBodyForm
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export default HomeTemplate;