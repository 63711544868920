import * as React from 'react'
import * as styles from '../styles/Hero.module.scss';
import { useSelector } from 'react-redux';

export const Hero = ({ backgroundLogo, backgroundVideo, backgroundVideoTablet, backgroundVideoDesktop, backgroundMobile, backgroundTablet, backgroundDesktop }) => {
    const isMobile = useSelector(state => state.isMobile);
    const isTablet = useSelector(state => state.isTablet);

    let heroVideo;
    let backgroundStyles;
    if (isMobile) {
        heroVideo = <div className={styles.HeroBackgroundVideo} dangerouslySetInnerHTML={{ __html: backgroundVideo.text }} />;
        backgroundStyles = {
            background: "url(" + backgroundMobile.url + ") no-repeat center / cover"
        }
    } else if (isTablet) {
        heroVideo = <div className={styles.HeroBackgroundVideo} dangerouslySetInnerHTML={{ __html: backgroundVideoTablet.text }} />;
        backgroundStyles = {
            background: "url(" + backgroundTablet.url + ") no-repeat center / cover"
        }
    } else {
        heroVideo = <div className={styles.HeroBackgroundVideo} dangerouslySetInnerHTML={{ __html: backgroundVideoDesktop.text }} />;
        backgroundStyles = {
            background: "url(" + backgroundDesktop.url + ") no-repeat center / cover"
        }
    }

    return (
        <section className={styles.HeroWrap} style={backgroundStyles}>
            <div className={styles.HeroOverlay}></div>
            {heroVideo}
            <img src={backgroundLogo.url} alt={""} className={styles.HeroLogo} />
        </section >
    );
}