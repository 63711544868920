import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { Swiper, SwiperSlide, useSwiperSlide } from 'swiper/react'
import Button from '../Button';
import * as styles from '../../styles/Carousel.module.scss'
import { useSelector } from 'react-redux';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Carousel = ({ slice }) => {
  let carouselItems;
  const isMobile = useSelector(state => state.isMobile);
  const isTablet = useSelector(state => state.isTablet);
  let numOfCans = 3;
  if (isMobile || isTablet) {
    numOfCans = 3;
  } else {
    numOfCans = 7;
  }

  const [activeSlide, setActiveSlide] = useState(null);
  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    if (swiper) {
      const activeSlide = slice.items[swiper.realIndex];
      setActiveSlide(activeSlide);
    }
  }, [activeSlide, swiper, slice.items]);

  const activeIndexChange = () => {
    if (swiper) {
      setActiveSlide(slice.items[swiper.realIndex]);
    }
  }

  const canClick = (canIndex) => {
    swiper.slideToLoop(canIndex, 500, true);
  }

  const SwiperDetails = ({ image, index }) => {
    const swiperSlide = useSwiperSlide();
    let slideImage = getImage(image.localFile);
    return swiperSlide.isActive ?
      <GatsbyImage image={slideImage} alt={image.alt ? image.alt : ''} className={[styles.CarouselImage, swiperSlide.isActive ? styles.CarouselImageActive : ''].join(' ')} />
      : <div onClick={() => canClick(index)} onKeyDown={() => canClick(index)}>
        <GatsbyImage image={slideImage} alt={image.alt ? image.alt : ''} className={styles.CarouselImage} />
      </div>;
  }

  const activeClickHandler = () => {
    window.open(
      activeSlide.carousel_cta_url.url,
      '_blank'
    );
  }

  let slideContent;

  if (slice.items) {
    carouselItems = slice.items.map((carouselItem, index) => {
      return (
        <SwiperSlide className={styles.SwiperSlide} key={index} id={index}>
          <SwiperDetails image={carouselItem.carousel_item_image} index={index}></SwiperDetails>
        </SwiperSlide>
      )
    })
  }

  if (activeSlide) {
    if (activeSlide.available) {
      slideContent = (<div className={styles.ActiveSlideDetailsWrap}>
        <PrismicRichText field={activeSlide.carousel_item_copy.richText} />
        <div className={styles.CarouselButtonWrap}>
          <Button copy={activeSlide.carousel_cta_copy.text} clickHandler={activeClickHandler} />
        </div>
      </div>);
    } else {
      slideContent = (
        <div className={styles.ActiveSlideDetailsWrap}>
          <PrismicRichText field={activeSlide.carousel_item_copy.richText} />
          <div className={styles.CarouselButtonWrap}>
            <Button copy={"Sold Out"} />
          </div>
        </div>
      )
    }
  }

  return (
    <div className={styles.CarouselWrap}>
      <Swiper
        onSwiper={setSwiper}
        slidesPerView={numOfCans}
        className={styles.SwiperWrap}
        loop={true}
        centeredSlides={true}
        onActiveIndexChange={() => activeIndexChange()}
      >
        {carouselItems}
      </Swiper>
      {slideContent}
    </div>
  )
}

export const query = graphql`
  fragment SectionDataBodyCarousel on PrismicSectionDataBodyCarousel {
    primary {
      carousel_background_image {
        alt
       url
      }
    }
    items {
      carousel_cta_copy {
        text
      }
      carousel_cta_url {
        url
      }
      carousel_item_copy {
        richText
      }
      carousel_item_image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      available
    }
  }
`
export default Carousel
