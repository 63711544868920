import React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import * as styles from '../../styles/ContentBlock.module.scss';
import Curly from '../../images/curly.svg';

const ContentBlock = ({ slice }) => {
    return (
        <div className={styles.ContentBlockWrap}>
            { slice.primary.decoration ? <img className={styles.CurlyLeft} src={Curly} alt="Page Decoration" /> : '' }
            { slice.primary.decoration ?  <img className={styles.CurlyRight} src={Curly} alt="Page Decoration" /> : '' }
           <PrismicRichText field={slice.primary.content_block.richText}/>
        </div>
    );
}

export const query = graphql`
    fragment SectionDataBodyContentBlock on PrismicSectionDataBodyContentBlock {
        primary {
            decoration
            content_block {
              richText
            }
          }
    }
`;
export default ContentBlock; 

