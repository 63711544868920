import React from 'react';
import { graphql } from 'gatsby';
import Button from '../Button';
import { useSelector } from 'react-redux';
import * as styles from '../../styles/FullBackgroundCTA.module.scss';


const FullBackgroundCTA = ({ slice }) => {
  const isMobile = useSelector(state => state.isMobile);
  const isTablet = useSelector(state => state.isTablet);
  let video;
  let backgroundStyles;
  if (isMobile) {
    video = <div className={styles.FullBackgroundCTAVideo} dangerouslySetInnerHTML={{ __html: slice.primary.full_background_video.text }} />;
    backgroundStyles = {
      background: "url(" + slice.primary.full_background_image.url + ") no-repeat center / cover",
  };
  } else if (isTablet) {
    video = <div className={styles.FullBackgroundCTAVideo} dangerouslySetInnerHTML={{ __html: slice.primary.full_background_video_tablet.text }} />;
    backgroundStyles = {
      background: "url(" + slice.primary.full_background_tablet_image.url + ") no-repeat center / cover",
  };
  } else {
    video = <div className={styles.FullBackgroundCTAVideo} dangerouslySetInnerHTML={{ __html: slice.primary.full_background_video_desktop.text }} />;
    backgroundStyles = {
      background: "url(" + slice.primary.full_background_desktop_image.url + ") no-repeat center / cover",
  };
  }

  const clickHandler = () => {
    window.open(
      slice.primary.cta_link.url,
      '_blank'
    );
  }

  return (
    <div className={styles.FullBackgroundCTAWrapper} style={backgroundStyles}>
      { video }
      <div className={styles.FullBackgroundCTAButton}>
        <Button copy={slice.primary.cta_copy.text} clickHandler={clickHandler} />
      </div>
    </div>
  );
}

export const query = graphql`
    fragment SectionDataBodyFullBackgroundWCta on PrismicSectionDataBodyFullBackgroundWCta {
        primary {
            full_background_video {
              text
            }
            full_background_video_tablet {
              text
            }
            full_background_video_desktop {
              text
            }
            full_background_image {
              url
            }
            full_background_tablet_image {
              url
            }
            full_background_desktop_image {
              url
            }
            cta_copy {
              text
            }
            cta_link {
              url
            }
        }
    }
`;
export default FullBackgroundCTA;
