import React, { useState, useEffect } from 'react';
import { Hero } from './Hero';
import Section from './Section';
import Modal from './Modal';
import Interstitial from './Interstitial';
import { useSelector } from 'react-redux';
import reduxStore from '../redux/reduxStore';
import { setOpenModal, setOverAge, setActiveSection } from '../redux/actions/actions';
import * as styles from '../styles/Homepage.module.scss';

const Homepage = ({ heroData, homepageSections, interstitialData }) => {
    let homeSections = null;
    const overAge = useSelector(state => state.overAge);
    const [observer, setObserver] = useState(null);
    const [showInterstitial, setShowInterstitial] = useState(false);

    useEffect(() => {
        setObserver(new IntersectionObserver(handleObserverEvent, {threshold: .1}))

        if(!overAge){
            setShowInterstitial(true);
        }

        return () => {
            if (observer) observer.disconnect()
        }
    }, []);

    const handleObserverEvent = entries => {
        entries.forEach(entry => {
            if(entry.isIntersecting){
                reduxStore.dispatch(setActiveSection(entry.target.title));
            }
        });
    }

    const observeElement = ref => {
        if (observer) observer.observe(ref)
    }

    const closeInterstitial = () => {
        localStorage.setItem('overAge', true);
        reduxStore.dispatch(setOverAge(true));
        reduxStore.dispatch(setOpenModal(false));
        setShowInterstitial(false);
      }

    if (homepageSections) {
        homeSections = homepageSections.map((section, index) => {
            return (
                <Section data={section} key={index} observeElement={observeElement} />
            );
        });
    }

    if (!overAge) {
        reduxStore.dispatch(setOpenModal(true));
    }

    return (
        <div className={styles.HomepageWrap}>
            {showInterstitial ? <Modal reducedOpacity={true}><Interstitial data={interstitialData} callback={closeInterstitial}/></Modal> : ''}
            <Hero backgroundLogo={heroData.hero_background_image} backgroundVideo={heroData.hero_background_embed} backgroundVideoTablet={heroData.hero_background_embed_tablet} backgroundVideoDesktop={heroData.hero_background_embed_desktop} backgroundMobile={heroData.hero_background_mobile} backgroundTablet={heroData.hero_background_tablet} backgroundDesktop={heroData.hero_background_desktop}/>
            {homeSections}
        </div>
    );
};

export default Homepage;