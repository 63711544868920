import React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import * as styles from '../../styles/Events.module.scss';
import Button from '../Button';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Events = ({ slice }) => {
  let events;
  let months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const eventCTAHandler = url => {
    window.open(url, '_blank');
  }

  if (slice.items) {
    let currentDate = new Date();
    events = slice.items.map((event, index) => {
      let eventDate = new Date(event.event_date);
      eventDate.setHours(23,59,59);
      let formattedDate = months[eventDate.getMonth()] + " " + eventDate.getDate();
      if (event.event_image.localFile && eventDate >= currentDate) {
        let eventImage = getImage(event.event_image.localFile)
        return (
          <li key={index} className={styles.EventItemWrap}>
            <div className={styles.EventImageWrap}>
              <GatsbyImage image={eventImage} alt={event.event_image.alt ? event.eventImage.alt : ''} className={styles.EventItemImage} />
              {event.event_cta_title.text !== '' && event.event_cta_url.url !== null ? <Button copy={event.event_cta_title.text} clickHandler={() => eventCTAHandler(event.event_cta_url.url)} /> : ''}
            </div>
            <p className={styles.EventDate}>{formattedDate}</p>
            <PrismicRichText field={event.event_copy.richText} />
          </li>
        );
      } else {
        return null;
      }
    });
  }
  return (
    <div className={styles.EventsWrap}>
      <ul className={styles.EventItemsWrap}>{events}</ul>
    </div>
  );
}

export const query = graphql`
    fragment SectionDataBodyEvents on PrismicSectionDataBodyEvent {
        items {
            event_image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            event_date(formatString: "MM/DD/YYYY")
            event_copy {
              richText
            }
            event_cta_title {
              text
            }
            event_cta_url {
              url
            }
          }
    }
`;
export default Events;
