import { PrismicRichText } from '@prismicio/react';
import React from 'react';
import Button from '../components/Button';
import Logo from '../images/homeplace-logo.svg';
import * as styles from '../styles/Interstitial.module.scss';

const Interstitial = (props) => {
    const underAge = () => {
        window.location.href = "http://www.google.com";
    }

    return (
        <div className={styles.InterstitialWrap}>
            <PrismicRichText field={props.data.interstitial_copy.richText}/>
            <div className={styles.ButtonWrap}>
                <Button copy={"Yes"} clickHandler={props.callback}/>
                <Button copy={"No"} clickHandler={underAge}/>
            </div>
            <img className={styles.InterstitialLogo} src={Logo} alt="Homeplace Beer Co." />
        </div>
    );
}

export default Interstitial;