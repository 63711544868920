import React from 'react';
import { graphql } from 'gatsby';
import HubspotForm from 'react-hubspot-form';
import * as styles from '../../styles/Form.module.scss';

const Form = ({ slice }) => {
    let formData = slice.primary.form.document.data;
    return (
        <div className={styles.FormWrap}>
            <HubspotForm
                portalId={formData.form_portal_id.text}
                formId={formData.form_id.text}
                loading={<div>Loading...</div>}
            />
        </div>
    );
}

export const query = graphql`
    fragment SectionDataBodyForm on PrismicSectionDataBodyForm {
        primary {
            form {
              document {
                ... on PrismicForm {
                  id
                  data {
                    form_id {
                      text
                    }
                    form_portal_id {
                      text
                    }
                  }
                }
              }
            }
          }
    }
`;
export default Form;
