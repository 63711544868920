import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import Button from '../Button';
import Modal from '../Modal';
import reduxStore from '../../redux/reduxStore';
import { useSelector } from 'react-redux';
import { setOpenModal } from '../../redux/actions/actions';
import * as styles from '../../styles/Venue.module.scss';
import FloorPlanIcon from '../../images/icn-fp.svg';
import PlayIcon from '../../images/Ic-play.svg';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Venue = ({ slice }) => {
  const [showFloorPlan, setShowFloorPlan] = useState(false);
  const [showVrTour, setShowVrTour] = useState(false);

  const isMobile = useSelector(state => state.isMobile);
  const isTablet = useSelector(state => state.isTablet);

  const openFloorPlan = () => {
    setShowFloorPlan(true);
    reduxStore.dispatch(setOpenModal(true));
  }

  const closeFloorPlan = () => {
    setShowFloorPlan(false);
    reduxStore.dispatch(setOpenModal(false));
  }

  const openVrTour = () => {
    setShowVrTour(true);
    reduxStore.dispatch(setOpenModal(true));
  }

  const closeVrTour = () => {
    setShowVrTour(false);
    reduxStore.dispatch(setOpenModal(false));
  }

  const scrollToEl = () => {
    setTimeout(function () {
      document.getElementById(slice.primary.venue_cta_id.text).scrollIntoView({ behavior: "smooth" });
    }, 100);
  }

  let imageBackground;
  if (isMobile) {
    imageBackground = getImage(slice.primary.venue_background_mobile.localFile);
  } else if (isTablet) {
    imageBackground = getImage(slice.primary.venue_background_tablet.localFile);
  } else {
    imageBackground = getImage(slice.primary.venue_background_desktop.localFile);
  }

  const floorPlanImage = getImage(slice.primary.floor_plan_image.localFile);

  return (
    <div className={styles.VenueWrap}>
      <GatsbyImage image={imageBackground} alt={''} layout="fullWidth" className={styles.VenueBackgroundImage}/>
      <div className={styles.VenueBodyWrap}>
        {isMobile || isTablet ? <PrismicRichText field={slice.primary.venue_description.richText} /> : ''}
        <div className={styles.VenueButtonsWrap}>
          {slice.primary.floor_plan_image ?
            <div className={styles.FloorPlanWrap}>
              <button className={styles.VenueButton} onClick={() => { openFloorPlan() }}>
                <img className={styles.VenueFloorPlanIcon} src={FloorPlanIcon} alt="" />
                Floor Plan
              </button>
            </div> : ''}
          {!isMobile && !isTablet ? <div className={styles.VenueDescDesktop}><PrismicRichText field={slice.primary.venue_description.richText} /></div> : ''}
          {slice.primary.venue_vr_tour ?
            <div className={styles.VRTourWrap}>
              <button className={styles.VenueButton} onClick={() => openVrTour()}>
                <img className={styles.VenueVrTour} src={PlayIcon} alt="" />
                Vr Tour
              </button>
            </div> : ''}
        </div>
        <div className={styles.VenueBookButtonWrap}>
          <Button copy={"Book Now"} clickHandler={scrollToEl} />
        </div>
      </div>
      {showFloorPlan ? <Modal callback={closeFloorPlan}><div className={styles.VenueFloorPlanModalWrap}><GatsbyImage image={floorPlanImage} alt={slice.primary.floor_plan_image.alt} className={styles.FloorPlanImage}/></div></Modal> : ''}
      {showVrTour ? <Modal callback={closeVrTour}><div className={styles.VenueVRModalWrap} dangerouslySetInnerHTML={{ __html: slice.primary.venue_vr_tour.text }} /></Modal> : ''}
    </div>
  )
}

export const query = graphql`
    fragment SectionDataBodyVenue on PrismicSectionDataBodyVenue {
        primary {
            floor_plan_image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              }
              venue_background_mobile {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              venue_background_tablet {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              venue_background_desktop {
                alt
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              venue_description {
                richText
              }
              venue_vr_tour {
                text
              }
              venue_cta_id {
                text
              }
          }
    }
`;

export default Venue;