import React from 'react';
import * as styles from '../styles/Button.module.scss';
import ButtonIcon from "../images/button.svg";

/**
 * 
 * @param {copy, clickHandler()} props 
 * @returns 
 */
const Button = (props) => {
    if(props.clickHandler) {
        return (
            <button className={styles.ButtonWrap} onClick={() => props.clickHandler()}><img src={ButtonIcon} alt={''}/><div className={styles.ButtonCopy}>{props.copy}</div></button>
        );
    } else {
        return (
            <button className={styles.ButtonWrap}><img src={ButtonIcon} alt={''}/><div className={styles.ButtonCopy}>{props.copy}</div></button>
        );
    }
   
}

export default Button;