import Carousel from './Carousel';
import CenteredLogo from './CenteredLogo';
import ContentBlock from './ContentBlock';
import FullBackgroundCTA from './FullBackgroundCTA';
import List from './List';
import Events from './Events';
import TwoThirdsBackground from './TwoThirdsBackground';
import Venue from './Venue';
import Form from './Form';

export const components = {
    carousel: Carousel,
    centered_logo: CenteredLogo,
    content_block: ContentBlock,
    full_background_w__cta: FullBackgroundCTA,
    list: List,
    event: Events,
    twothirds_background_image: TwoThirdsBackground,
    venue: Venue,
    form: Form
}